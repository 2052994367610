/* 
 * CSS additions for HPRS
 */

a[href^="mailto:"] {
	hyphens: none;
}

.floatleft {
	float: left;
	margin-right: 10px;
}

.floatright {
	float: right;
	margin-left: 10px;
}

.clearleft {
	clear: left;
}

.clearright {
	clear: right;
}

.insert-pic {
	margin: 4px;
}

a.arrowafter::after {
	content: " ›";
}

.centertext {
	text-align: center;
}

.fullwidth {
	width: 100%;
	background: darkred;
	color: white;
	text-align: center;
}

.highlight {
	background: yellow;
	color: black;
}

.top-bar-section ul li > a {
	font-size: 0.8rem;
}

.grant-statement {
	background: white;
}


.footer-logos {
	margin: 0 auto;
	background: white;
	border-radius: 2px;
	display: grid;
	grid-template-columns: repeat(auto-fill, minmax(350px, 1fr));
	grid-gap: 10px;

	> img {
		display: block;
		margin: 10px auto;
		max-width: 90%;
	}
}

.grant-logo {
	grid-column: 1 / -1;
	grid-row: 1;
}

.strikeout {
	text-decoration: line-through;
}

.nowrap {
	white-space: nowrap;
}

.thumbs-grid {
	display: grid;
	grid-template-columns: repeat(auto-fill, minmax(160px, 1fr));
	grid-gap: 25px;
}

.thumbs-thumb {
	position: relative;
	width: 150px;
	margin: 0 auto;
	img {
		display: block;
		max-width: 100%;
	}
}

.concert-poster {
	margin: 20px 5px;
	-webkit-box-shadow: 0px 3px 5px 3px rgba(0,0,0,0.75);
	-moz-box-shadow: 0px 3px 5px 3px rgba(0,0,0,0.75);
	box-shadow: 0px 3px 5px 3px rgba(0,0,0,0.75);
}

.radio-item {
	display: inline-block;
}

.error {
	color: red
}

.error-bg {
	background-color: #fdd !important;
}

.small-text {
	font-size: 0.7em;
}

.blue-text {
	color: blue;
}

/* Oops! looks like these were forgotton in _07_layout.scss */
body.video ul, body.video ol {
	color: #fff;
}

@media(max-width: 500px) {
	.floatleft {
		float: none;
		display: block;
	}

	.floatright {
		float: none;
		display: block;
	}
}
